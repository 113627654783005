import isMobile from "is-mobile"
function isTabletIOS() {
  return (
    navigator &&
    navigator.maxTouchPoints > 1 &&
    navigator.userAgent.indexOf("Macintosh") !== -1 &&
    navigator.userAgent.indexOf("Safari") !== -1
  )
}

export default {
  install: function (Vue, _options) {
    return Vue.mixin({
      data: () => ({
        mobileVariant: isMobile(),
        tabletIOSVariant: isTabletIOS(),
      }),
    })
  },
}
