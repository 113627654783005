const plugin = {
  instances: [],
  bind: function () {
    document.addEventListener("turbo:before-cache", function () {
      return plugin.cleanupInstances()
    })
  },
  cleanupInstances: function () {
    let ref = plugin.instances
    for (let i = 0, len = ref.length; i < len; i++) {
      const instance = ref[i]
      instance.$destroy()
    }
    plugin.instances = []
  },
  Mixin: {
    beforeMount: function () {
      if (this === this.$root) {
        plugin.instances.push(this)
        this.$elementDataset = this.$el.dataset
        this.$elementId = this.$el.getAttribute("id")
        this.$elementClasses = this.$el.classList || []
      }
    },
    destroyed: function () {
      if (this === this.$root && this.$el instanceof HTMLElement) {
        this.$el.setAttribute("id", this.$elementId)
        for (const key in this.$elementDataset) {
          this.$el.dataset[key] = this.$elementDataset[key]
        }
        for (const className in this.$elementClasses) {
          this.$el.classList.add(className)
        }
      }
    },
  },
  install: function (Vue, _options) {
    plugin.bind()
    return Vue.mixin(plugin.Mixin)
  },
}

export default plugin
