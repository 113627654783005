import Vue from "vue"
import VueMq from "vue-mq"
import { sizeXS, sizeSM, sizeMD, sizeLG } from "@/lib/screenSizes"

Vue.use(VueMq, {
  breakpoints: {
    xs: sizeXS,
    sm: sizeSM,
    md: sizeMD,
    lg: sizeLG,
  },
})
