export default {
  install: function (Vue, _options) {
    return Vue.mixin({
      data: () => ({
        currentMarket: {
          isCore: window.config.marketCore,
          handle: window.config.marketHandle,
          email: window.config.marketEmail,
        },
      }),
    })
  },
}
